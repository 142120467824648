import { type ClassValue, clsx } from "clsx";
import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
/**
 * If seconds < 1 hour - display 00:00 format
 * I seconds >= 1 hour - display 00:00:00 format
 */
export function formatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60); // Ensure this is an integer

  const pad = (num: number) => String(num).padStart(2, "0");

  if (hours > 0) {
    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  } else {
    return `${pad(minutes)}:${pad(remainingSeconds)}`;
  }
}

export const isIOS = () => {
  const ua = navigator.userAgent;
  return /iPad|iPhone|iPod/.test(ua);
};

export const formattedDateWithTimezone = ({
  recordingDate,
  timezone,
}: {
  recordingDate: string;
  timezone: string | undefined;
}) => {
  return DateTime.fromISO(recordingDate, {
    zone: timezone,
  }).toFormat("EEE, LLL dd, yyyy");
};
